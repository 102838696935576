import React from 'react';
import Layout from 'sections/Layout';
import Page404 from 'sections/pages/404';

const PageNotFound = () => {
  return (
    <Layout
      currentPageClass={'cr-404-page'}
      headerClass={'cr-404-header'}
      mainClass={'cr-404-main'}
      footerClass={'cr-404-footer'}
    >
      {(commonData) => <Page404 />}
    </Layout>
  );
};

export default PageNotFound;
