import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import * as styles from './Page404.module.scss';
import Icon from 'components/UI/Icon';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Page404 = () => {
  const [data, setData] = useState(null);
  const { notFoundPage } = useReusableBlocks();

  useEffect(() => {
    setData(notFoundPage);
  }, []); // eslint-disable-line

  return (
    <div className={classnames('cr-wrap', styles.wrap)}>
      <div className={styles.inner}>
        <h2 className="cr-h2">{data?.title}</h2>
        <p className={classnames('cr-lead', styles.text)}>{data?.subtitle}</p>
        <p>
          <Link
            to={data?.button?.url.url}
            className={classnames(
              styles.link,
              'cr-btn cr-btn--large cr-btn--wide cr-btn--icon cr-btn--icon-red-txt'
            )}
          >
            {data?.button?.title}
            <Icon name="corner-arrow-small-bold" />
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Page404;
